import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  min-height: 673px;
  background-color: #FCF8EE;

  .img {
    margin: 0 auto;
  }

  h2 {
    color: #72370E;
  }

  a {
    background-color: #EA7100;
    margin: 0 auto;
    width: 100%;  

    @media ${device.tablet} {
      width: 600px;
    }

    @media ${device.desktopXXL} {
      position: relative;
      top: 60px;
    }
  }

  @media ${device.tablet} {
    min-height: 418px;
  }
  @media ${device.desktopLG} {
    min-height: 505px;
  }
  @media ${device.desktopXL} {
    min-height: 648px;
  }

  .image {
    height: 90px;
    
    @media ${device.tablet} {
      height: 90px;
    }
    @media ${device.desktopLG} {
      height: 130px;
    }
    @media ${device.desktopXL} {
      height: 150px;
    }
  }

  .text {
    height: 50px;

    @media ${device.tablet} {
      height: 70px;
    }
    @media ${device.desktopLG} {
      height: 90px;
    }
    @media ${device.desktopXL} {
      height: 80px;
      width: 270px;
    }
  }
`
