import React, { useRef, useEffect } from 'react'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'
import { animated, useTrail, useSpring } from 'react-spring'
import Tags from './tags/_index'

import * as S from './style'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

const Hero = () => {
  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))
  const [ animatedImage, setAnimatedBackground ] = useSpring(() => (translateFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
      setAnimatedBackground((translateTo))
    }
  }, [ scroll ])

  return (
    <S.Section
      className='d-flex align-items-xl-center'
      ref={sectionRef} style={animatedImage}
      role='img' aria-label='Tela de celular mostrando a home do super app Inter'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-6 position-relative'>
            <animated.div style={animatedSection[2]}><Tags /></animated.div>
            <animated.h1 style={animatedSection[0]} className='font-citrina text-white text-center text-md-left fw-500 fs-32 fs-md-40 fs-lg-48 fs-xl-65 lh-47 lh-lg-60 lh-xl-80 mb-5'>
              <span className='d-block'>Um jeito inteligente</span> <span className='d-block'>de cuidar do seu</span> dinheiro.
            </animated.h1>
            <animated.p style={animatedSection[1]} className='fs-14 fs-lg-18 lh-16 lh-lg-22 text-white text-center text-md-left mt-4'>
              Primeiro, revolucionamos o mercado com a primeira conta digital e gratuita do Brasil. Depois, fomos além e lançamos um Super App financeiro completo, para transformar a sua relação com o dinheiro.
            </animated.p>
          </div>
        </div>
      </div>
    </S.Section>
  )
}

export default Hero
