import styled from "styled-components"
import * as newColors from 'src/styles/newColors'
import { device } from "src/styles/breakpoints"

export const Card = styled.article`
  background-color: ${newColors.primary[500]};
  height: 249px;
  
  @media ${device.tablet}{
    height: 289px;
  }

  @media ${device.desktopLG}{
    height: 320px;
  }

  .card{
    &__icon{
      width: 24px;
      height: 24px;
      margin-bottom: 24px;
      
      @media ${device.tablet}{
        width: 32px;
        height: 32px;
      }
    }
    &__title{
      letter-spacing: 0;
    }

    &__description, &__links{
      letter-spacing: 0;      
    }
  }
`
