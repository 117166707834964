import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { primary } from 'src/styles/newColors'

export const Card = styled.div`
  background-color: ${primary[500]};
  border-radius: 20px;
  padding: 35px 30px;
  position: relative;
  top: 21px;

  @media ${device.tablet} {
    padding: 35px 21px;
  }

  @media ${device.desktopLG} {
    top: 41px;
  }

  @media ${device.desktopXL} {
    top: 11px;
    padding: 35px 36px;
  }

  @media ${device.desktopXXXL} {
    top: -11px;
    padding: 35px 36px;
  }
`

export const Li = styled.li`
  list-style: none;

  img {
    width: 30px;

    @media ${device.desktopLG} {
      width: 40px;
    }
  }
`
