import styled from "styled-components"
import { device } from "src/styles/breakpoints"

export const Section = styled.section`
  padding: 0 0 24px 0;
  overflow: hidden;

  .react-multi-carousel-item {
    margin-right: 15px;
  }

  @media ${device.desktopLG}{
    padding: 34px 24px 64px 41px;
  }

  @media ${device.desktopXL}{
    padding: 66px 156px 96px 156px;
  }

  @media ${device.desktopXXL}{
    padding: 66px 156px 96px 150px;
  }
  
  @media ${device.desktopXXXL}{
    padding: 66px 156px 96px 90px;
  }

  @media ${device.desktopXXLL}{
    padding: 66px 156px 96px 250px;
  }

  .content_wrapper{
    &__carousel{
      padding: 0 24px;
      overflow: visible;

      @media ${device.tablet}{
        padding: 0 35px;
      }
      @media ${device.desktopLG}{
        padding: 0;
      }
    }
  }
`
