import React, { useRef, useEffect } from 'react'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'
import { animated, useTrail, useSpring } from 'react-spring'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { WIDTH_MD, WIDTH_LG, WIDTH_XL } from 'src/styles/breakpoints'
import useWidth from 'src/hooks/window/useWidth'
import ImageWebp from 'src/components/ImageWebp'

import * as S from './style'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const translateFrom = {
  config: {
    duration: 600,
  },
  opacity: 0,
  transform: 'translateY(40px)',
}

const translateTo = {
  opacity: 1,
  transform: 'translateY(0)',
}

const images = {
  sm: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-nasdaq-360/image.webp',
  md: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-nasdaq-768/image.webp',
  lg: 'https://central-imagens.bancointer.com.br/images-without-small-versions/nasdaq-sobre-o-inter/image.webp',
  xl: 'https://central-imagens.bancointer.com.br/images-without-small-versions/inter-nasdaq-1440/image.webp',
}

const getImageByWidth = (width: number) => {
  if (width < WIDTH_MD) return images.sm
  if (width < WIDTH_LG) return images.md
  if (width < WIDTH_XL) return images.lg
  return images.xl
}

const PremiosEReconhecimentos = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const width = useWidth()

  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))
  const [ animatedImage, setAnimatedBackground ] = useSpring(() => (translateFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
      setAnimatedBackground((translateTo))
    }
  }, [ scroll ])

  return (
    <S.Section ref={sectionRef} className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <animated.div className='col-12'>
            <h2
              style={animatedSection[0]}
              className='font-citrina text-grayscale--500 fs-28 lh-33 fs-md-40 fs-lg-48 lh-md-44 lh-lg-52 my-3 text-center'
            >
              Prêmios e reconhecimentos
            </h2>
            <p style={animatedSection[0]} className='fs-16 fs-lg-18 lh-19 lh-md-20 text-grayscale--500 text-center'>
              No Inter, inovamos todos os dias para que nossos clientes tenham uma vida financeira mais eficiente e inteligente. É com muito orgulho que vemos essa missão ser traduzida em reconhecimento.
            </p>
          </animated.div>
        </div>
        <div className='row mt-4 mt-md-0'>
          <animated.div style={animatedImage} className='col-12 col-md-6 col-lg-5 mb-4 mb-md-0 text-center pt-1 mt-md-2 mt-lg-3'>
            <ImageWebp
              pathSrc={getImageByWidth(width)}
              altDescription='Apresentação Inter&Co no telão da Nasdaq na Times Square'
              arrayNumbers={[ 312, 316, 401, 507 ]}
              arrayNumbersHeight={[ 306, 477, 637, 637 ]}
              className='img'
            />
            <p className='fs-14 fs-md-16 fs-lg-20 fs-xl-23 lh-17 lh-md-20 lh-lg-24 lh-xl-28 mt-3 fw-500 text-center text-grayscale--500 font-citrina'>Nasdaq Listed</p>
          </animated.div>
          <animated.div style={animatedSection[1]} className='col-10 col-md-6 offset-1 offset-md-0 offset-lg-1'>
            <div className='d-flex justify-content-between'>
              <div className='d-flex justify-content-center flex-column'>
                <div className='image d-flex align-items-center'>
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/selo-worlds-best-banks/image.webp'
                    altDescription='Melhores bancos do mundo - Forbes 2024'
                    arrayNumbers={[ 119.95, 140.95, 200, 251 ]}
                    arrayNumbersHeight={[ 60, 69.97, 93.33, 120, 107.58 ]}
                    className='img'
                  />
                </div>
                <p className='fs-14 fs-md-16 fs-lg-20 fs-xl-23 lh-17 lh-md-20 lh-lg-24 lh-xl-28 fw-500 text-center text-grayscale--500 mt-2 font-citrina text'>
                  <span className='d-block'>Melhores bancos do</span> mundo - Forbes 2024
                </p>
              </div>
              <div className='d-flex justify-content-center flex-column'>
                <div className='image d-flex align-items-center'>
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/selo-100-influentes/image.webp'
                    altDescription='100 empresas mais influentes do Brasil - Veja Negócios 2024'
                    arrayNumbers={[ 119.95, 119.95, 200, 251 ]}
                    arrayNumbersHeight={[ 60, 59.97, 93.33, 120, 105 ]}
                    className='img'
                  />
                </div>
                <p className='fs-14 fs-md-16 fs-lg-20 fs-xl-23 lh-17 lh-md-20 lh-lg-24 lh-xl-28 fw-500 text-center text-grayscale--500 mt-2 font-citrina text'>
                  <span className='d-block'>100 empresas mais</span>  <span className='d-block'>influentes do Brasil -</span> Veja Negócios 2024
                </p>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <div className='d-flex justify-content-center flex-column'>
                <div className='image d-flex align-items-center'>
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/selo-pro-etica/image.webp'
                    altDescription='Selo Empresa Pró-Ética 2022-2023'
                    arrayNumbers={[ 119.95, 139.95, 190, 190 ]}
                    arrayNumbersHeight={[ 80, 89.97, 133.33, 120, 113 ]}
                    className='img'
                  />
                </div>
                <p className='fs-14 fs-md-16 fs-lg-20 fs-xl-23 lh-17 lh-md-20 lh-lg-24 lh-xl-28 fw-500 text-center text-grayscale--500 mt-2 font-citrina text'>
                  <span className='d-block'>Selo Empresa</span> Pró-Ética 2022-2023
                </p>
              </div>
              <div className='d-flex justify-content-center flex-column'>
                <div className='image d-flex align-items-center'>
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/selo-conarec/image.webp'
                    altDescription='Excelência na experiência do cliente - Prêmio CONAREC 2024'
                    arrayNumbers={[ 119.95, 130.95, 170, 200 ]}
                    arrayNumbersHeight={[ 45, 45.97, 63.33, 70.58, 70 ]}
                    className='img'
                  />
                </div>
                <p className='fs-14 fs-md-16 fs-lg-20 fs-xl-23 lh-17 lh-md-20 lh-lg-24 lh-xl-28 fw-500 text-center text-grayscale--500 mt-2 font-citrina text'>
                  <span className='d-block d-xl-inline'>Excelência na</span> <span className='d-block d-xl-inline'>experiência do</span> <span className='d-block'>cliente - Prêmio</span> CONAREC 2024
                </p>
              </div>
            </div>
            <div className='d-flex justify-content-between'>
              <div className='d-flex justify-content-center flex-column'>
                <div className='image d-flex align-items-center'>
                  <ImageWebp
                    pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/selo-prevencao/image.webp'
                    altDescription='Selo de Prevenção a Fraudes - CNF/Febabran 2024'
                    arrayNumbers={[ 136, 159.95, 200, 251 ]}
                    arrayNumbersHeight={[ 90, 99.97, 133.33, 167.58 ]}
                    className='img'
                  />
                </div>
                <p className='fs-14 fs-md-16 fs-lg-20 fs-xl-23 lh-17 lh-md-20 lh-lg-24 lh-xl-28 fw-500 text-center text-grayscale--500 mt-2 font-citrina text'>
                  <span className='d-block d-xl-inline'>Selo de Prevenção</span> <span className='d-block d-xl-inline'>a Fraudes - CNF/</span>Febabran 2024
                </p>
              </div>
            </div>
          </animated.div>
          <animated.a
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_4',
                section_name: 'Prêmios e reconhecimentos',
                element_action: 'click button',
                element_name: 'Confira todos os nossos prêmios',
                redirect_url: 'https://investors.inter.co/',
              })
            }}
            target='blank'
            style={animatedSection[2]}
            href='https://blog.inter.co/premios-do-inter/?_gl=1*1dxf7hy*_gcl_au*MTQ5MTI2MTIxMi4xNzI2NjEwMzYx'
            className='btn--lg rounded-2 fs-14 fw-600 text-none mt-2 mt-md-4 mb-xl-5 text-white d-flex justify-content-center align-items-center'
          >
            Confira todos os nossos prêmios
          </animated.a>
        </div>
      </div>
    </S.Section>
  )
}

export default PremiosEReconhecimentos
