import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const DigitalAccount = styled.div`
  position: absolute;
  top: -13px;
  left: calc(50% - 126px);

  @media ${device.tablet} {
    left: calc(50% - 185px);
    top: -18px;
  }

  @media ${device.desktopLG} {
    left: calc(50% - 213px);
    top: -23px;
  }

  @media ${device.desktopXL} {
    left: calc(50% - 253px);
    top: -20px;
  }

  .text {
    border-radius: 20px;
    background-color: #8BC0E9;
    
    span {
      font-size: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 12px 5px 12px;
      color: #000000;

      @media ${device.tablet} {
        font-size: 10px;
        padding: 4px 12px 4px 12px;
      }
      @media ${device.desktopLG} {
        font-size: 12px;
        padding: 7px 12px 7px 12px;
      }
      @media ${device.desktopXL} {
        font-size: 17px;
        padding: 5px 12px 5px 12px;
      }
    }
  }

  .content-icon {
    border-radius: 50%;
    width: 23px;
    height: 23px;
    background-color: #FFC591;
    
    @media ${device.tablet} {
      width: 27px;
      height: 27px;
    }

    @media ${device.desktopLG} {
      width: 36px;
      height: 36px;
    }

    @media ${device.desktopXL} {
      width: 38px;
      height: 38px;
    }
    
    img {
      width: 11px;
    }
  }

`

export const Shopping = styled.div`
  position: absolute;
  top: 38px;
  right: calc(50% - 141px);

  @media ${device.tablet} {
    right: calc(50% - 162px);
    top: 37px;
  }

  @media ${device.desktopLG} {
    right: calc(50% - 203px);
    top: 42px;
  }
  @media ${device.desktopXL} {
    right: calc(50% - 270px);
    top: 61px;
  }

  .text {
    border-radius: 5px;
    background-color: #9CDBB3;

    span {
      font-size: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 12px 5px 12px;
      color: #000000;

      @media ${device.tablet} {
        font-size: 10px;
        padding: 4px 12px 4px 12px;
      }
      @media ${device.desktopLG} {
        font-size: 12px;
        padding: 5px 12px 5px 12px;
      }
      @media ${device.desktopXL} {
        font-size: 17px;
        padding: 5px 12px 5px 12px;
      }
    }
  }

  .content-icon {
    border-radius: 8px;
    width: 23px;
    height: 23px;
    background-color: ${white};

    @media ${device.tablet} {
      width: 27px;
      height: 27px;
    }

    @media ${device.desktopLG} {
      width: 36px;
      height: 36px;
    }

    @media ${device.desktopXL} {
      width: 38px;
      height: 38px;
    }

    img {
      width: 11px;

      @media ${device.desktopLG} {
        width: 15px;
      }
    }
  }
`

export const Investimentos = styled.div`
  position: absolute;
  top: 81px;
  left: calc(50% - 126px);

  @media ${device.tablet} {
    top: 84px;
    left: calc(50% - 35px);
  }

  @media ${device.desktopLG} {
    top: 106px;
  }

  @media ${device.desktopXL} {
    top: 143px;
    left: calc(50% - 28px);
  }
  
  .text {
    border-radius: 20px;
    background-color: #DCA9D8;
 
    span {
      font-size: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 12px 5px 12px;
      color: #000000;

      @media ${device.tablet} {
        font-size: 10px;
      }
      @media ${device.desktopLG} {
        font-size: 12px;
        padding: 5px 12px 5px 12px;
      }
      @media ${device.desktopXL} {
        font-size: 17px;
        padding: 5px 12px 5px 12px;
      }
    }
  }

  .content-icon {
    border-radius: 8px;
    width: 23px;
    height: 23px;
    background-color: ${white};

    @media ${device.tablet} {
      width: 27px;
      height: 27px;
    }

    @media ${device.desktopLG} {
      width: 36px;
      height: 36px;
    }

    @media ${device.desktopXL} {
      width: 38px;
      height: 38px;
    }

    img {
      width: 11px;

      @media ${device.desktopLG} {
        width: 15px;
      }
    }
  }
`

export const Emais = styled.div`
  position: absolute;
  top: 127px;
  right: calc(50% - 105px);

  @media ${device.tablet} {
    right: calc(50% - -15px);
    top: 132px;
  }
  @media ${device.desktopLG} {
    right: calc(50% - -6px);
    top: 162px;
  }
  @media ${device.desktopXL} {
    right: calc(50% - 1px);
    top: 222px;
  }
  

  .text {
    border-radius: 5px;
    background-color: #E5DC5F;

    span {
      font-size: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 12px 5px 12px;
      font-weight: 400;
      color: #000000;

      @media ${device.tablet} {
        font-size: 10px;
      }
      @media ${device.desktopLG} {
        font-size: 12px;
      }
      @media ${device.desktopXL} {
        font-size: 17px;
        padding: 5px 12px 5px 12px;
      }
    }
  }
  
  .content-icon {
    border-radius: 50%;
    width: 23px;
    height: 23px;
    background-color: #8F3D15;

    @media ${device.tablet} {
      width: 27px;
      height: 27px;
    }

    @media ${device.desktopLG} {
      width: 36px;
      height: 36px;
    }

    @media ${device.desktopXL} {
      width: 38px;
      height: 38px;
    }

    img {
      width: 11px;

      @media ${device.desktopLG} {
        width: 15px;
      }
    }
  }
`
