import React from 'react'

import Phone from '../../../assets/images/phone.png'
import Dollar from '../../../assets/images/dollar.png'
import Tag from '../../../assets/images/tag.png'

import * as S from './style'

const Tags = () => {
  return (
    <>
      <S.DigitalAccount className='d-flex align-items-center'>
        <div className='content-icon d-flex align-items-center justify-content-center'><img src={Phone} /></div>
        <div className='text'><span>Conta digital</span></div>
      </S.DigitalAccount>
      <S.Shopping className='d-flex align-items-center'>
        <div className='text'><span>Shopping</span></div>
        <div className='content-icon d-flex align-items-center justify-content-center'><img src={Dollar} /></div>
      </S.Shopping>
      <S.Investimentos className='d-flex align-items-center'>
        <div className='content-icon d-flex align-items-center justify-content-center'><img src={Dollar} /></div>
        <div className='text'><span>Investimentos</span></div>
      </S.Investimentos>
      <S.Emais className='d-flex align-items-center'>
        <div className='text'><span>e mais.</span></div>
        <div className='content-icon d-flex align-items-center justify-content-center'><img src={Tag} /></div>
      </S.Emais>
    </>
  )
}

export default Tags
