import styled from 'styled-components'
import { primary } from 'src/styles/newColors'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  min-height: 750px;
  background: ${primary[500]};
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-360-hero/image.webp');
  background-repeat: no-repeat;
  background-position: bottom right;
  padding-top: 90px;

  @media ${device.tablet} {
    min-height: 450px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-768-hero/image.webp');
    background-position: center right;
  }

  @media ${device.desktopLG} {
    min-height: 600px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-1024-hero/image.webp');
  }

  @media ${device.desktopXL} {
    min-height: 840px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/inter-1440-hero/image.webp');
    padding-top: 0;
  }
`
