import React, { useRef, useEffect } from 'react'
import useScroll from '../../../../hooks/window/useScroll'
import isVisible from '../../../../utils/isVisible'
import { animated, useTrail } from 'react-spring'

import * as S from './styles'
import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import OrangeCard from './components/OrangeCard/_index'
import * as newColors from 'src/styles/newColors'

import { cardsInfos, CardsInfosProps } from './_cardsInfos'

const fadeFrom = {
  config: {
    duration: 500,
  },
  opacity: 0,
}

const fadeTo = {
  opacity: 1,
}

const ImprensaEParcerias = () => {
  const scroll = useScroll(300)
  const sectionRef = useRef<HTMLElement>(null)

  const [ animatedSection, setAnimatedSection ] = useTrail(3, () => (fadeFrom))

  useEffect(() => {
    if (isVisible(sectionRef.current, -0.3)) {
      setAnimatedSection((fadeTo))
    }
  }, [ scroll ])

  return (
    <S.Section ref={sectionRef}>
      <animated.h2 style={animatedSection[0]} className='fs-lg-48 lh-md-52 text-grayscale--500 font-citrina d-none d-lg-block'>Imprensa e parcerias</animated.h2>
      <animated.div style={animatedSection[1]}>
        <DefaultCarousel
          customDotColor={newColors.primary[500]}
          sm={{ items: 1, partialVisibilityGutter: 60 }}
          md={{ items: 2, partialVisibilityGutter: 30 }}
          lg={{ items: 2, partialVisibilityGutter: 90 }}
          xl={{ items: 2, partialVisibilityGutter: 170 }}
          containerClass='content_wrapper__carousel'
        >
          { cardsInfos.map((card: CardsInfosProps, index: number) => (
            <OrangeCard
              title={card.title}
              description={card.description}
              downloadOrEmail={card.downloadOrEmail}
              icon={card.icon}
              key={`card${index}`}
              redirectUrl={card.redirectUrl}
              dataLayerParams={{
                section: 'dobra_08',
                element_action: 'click button',
                element_name: card.downloadOrEmail + ' - ' + card.title,
                section_name: 'Imprensa e parcerias',
                redirect_url: card.redirectUrl ?? '',
              }}
            />
          ))}
        </DefaultCarousel>
      </animated.div>
    </S.Section>
  )
}

export default ImprensaEParcerias
